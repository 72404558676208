import { Fragment, KeyboardEvent, memo, useCallback, useEffect, useState } from "react";
import { Button, ButtonTypes, ConfirmModal, IColumn, Input, Table } from "@clintonelec/react-storybook";
import { IHostname } from "Interfaces";
import { getAllHostnames, getHostnamesOptions, selectHostnameCount } from "Data/Selectors/Hostnames";
import Icon, { IconSize } from "Components/Icon";
import "Components/HostnameTable/HostnameTable.less";
import HostnameModal from "Components/HostnameModal";
import {
	deleteHostnameAction, fetchHostnamesPageAction, setHostnameOptionsAction, updateHostnameAction
} from "Data/Actions/Hostnames";
import { formatDate, IIndexSignature } from "@clintonelec/typescriptutils";
import { isEqual } from "lodash";
import { useAppDispatch, useAppSelector } from "Data/Redux/Store";
import { NotificationType, notify } from "src/Notifications";
import { SerializedError } from "@reduxjs/toolkit";

const tableColumns: IColumn[] = [
	{
		title: "Hostname",
		dataIndex: "hostname",
		key: "hostname",
		sortable: true
	},
	{
		title: "IPv4 Address",
		dataIndex: "ipv4",
		key: "ipv4",
		sortable: false
	},
	{
		title: "IPv6 Address",
		dataIndex: "ipv6",
		key: "ipv6",
		sortable: false
	},
	{
		title: "Online",
		dataIndex: "offline",
		key: "offline",
		sortable: true
	},
	{
		title: "Last Updated",
		dataIndex: "lastUpdated",
		key: "lastUpdated",
		sortable: false
	},
	{
		title: "",
		dataIndex: "action",
		key: "action",
		sortable: false
	}
];

const HostnameTable = () => {
	const dispatch = useAppDispatch();
	const [ editedHosts, setEditedHosts ] = useState<IIndexSignature<IHostname>>({ });
	const hosts = useAppSelector(getAllHostnames);
	const hostnamesOptions = useAppSelector(getHostnamesOptions);
	const hostnameCount = useAppSelector(selectHostnameCount);
	const deleteHostname = (id: string) => dispatch(deleteHostnameAction(id));
	const fetchHostnames = useCallback(
		() => dispatch(fetchHostnamesPageAction()),
		[ dispatch ]
	);

	const updateHostname = (hostname: IHostname) => dispatch(updateHostnameAction(hostname));
	const setHostnameOptions = (options: IIndexSignature<string | number>) =>
		dispatch(setHostnameOptionsAction(options));

	useEffect(() => {
		fetchHostnames();
	}, [ fetchHostnames ]);

	const handleInputChange = (hostname: IHostname, key: string) => (value: string) => {
		setEditedHosts({
			...editedHosts,
			[ hostname.id ]: {
				...editedHosts[ hostname.id ],
				[ key ]: value
			}
		});
	};

	const handleSearch = (event: KeyboardEvent<HTMLInputElement>) => {
		const searchTerm = event?.currentTarget?.value;
		const hostOptionsPayload: IIndexSignature<string | number> = {
			...hostnamesOptions,
			"filter[hostname]": searchTerm ? searchTerm : undefined
		};

		Object.keys(hostOptionsPayload).forEach(key => {
			if (hostOptionsPayload[ key ] === undefined) {
				delete hostOptionsPayload[ key ];
			}
		});

		if (isEqual(hostnamesOptions, hostOptionsPayload)) {
			return;
		}

		setEditedHosts({ });
		setHostnameOptions(hostOptionsPayload);
		fetchHostnames();
	};

	const renderOfflineIndicator = (hostname: IHostname) => {
		const { offline } = hostname;
		const iconClass = offline ? "icon-error" : "icon-success";

		return (
			<Icon
				name="circle-check"
				className={ iconClass }
				size={ IconSize.SMALLEST }
				fixedWidth
			/>
		);
	};

	const renderDeleteHostnameButton = (hostname: IHostname) => {
		if (editedHosts[ hostname.id ]) {
			return (
				<Icon
					className="action-button"
					name="close"
					size={ IconSize.SMALLEST }
					fixedWidth
					onClick={ toggleEditableRow(hostname) }
				/>
			);
		}

		return (
			<ConfirmModal
				okButton={ { text: "Delete", type: ButtonTypes.DANGER, onClick: handleDeleteHostname(hostname) } }
				cancelButton={ { text: "Cancel" } }
				title="Delete Hostname"
				modalContent={ "Are you sure you want to delete the hostname (s)?" }
			>
				<Icon className="action-button" name="trash" size={ IconSize.SMALLEST } fixedWidth />
			</ConfirmModal>
		);
	};

	const renderEditOrSaveButton = (hostname: IHostname) => {
		let icon = "pencil";

		if (editedHosts[ hostname.id ]) {
			icon = "save";
		}

		return (
			<Icon
				className="action-button"
				name={ icon }
				size={ IconSize.SMALLEST }
				fixedWidth
				onClick={ handleEditOrSaveClick(hostname) }
			/>
		);
	};

	const renderRowActionButtons = (hostname: IHostname) => (
		<Fragment>
			{ renderEditOrSaveButton(hostname) }
			{ renderDeleteHostnameButton(hostname) }
		</Fragment>
	);

	const getDataItem = (rowData: IHostname) => {
		const hostname = <span className="row-field">{ rowData.hostname }</span>;
		let ipv4 = <span className="row-field">{ rowData.ipv4 }</span>;
		let ipv6 = <span className="row-field">{ rowData.ipv6 }</span>;
		const lastUpdated = (
			<span className="row-field">
				{ formatDate(new Date(rowData.lastUpdated), "MM-DD-YYYY HH:mm:ss") }
			</span>
		);

		if (editedHosts[ rowData.id ]) {
			ipv4 = (
				<Input
					value={ rowData.ipv4 }
					onUpdate={ handleInputChange(rowData, "ipv4") }
				/>
			);

			ipv6 = (
				<Input
					value={ rowData.ipv6 }
					onUpdate={ handleInputChange(rowData, "ipv6") }
				/>
			);
		}

		return {
			key: rowData.id,
			action: renderRowActionButtons(rowData),
			hostname,
			ipv4,
			ipv6,
			offline: renderOfflineIndicator(rowData),
			lastUpdated
		};
	};

	const handleEditOrSaveClick = (hostname: IHostname) => () => {
		const { id } = hostname;

		if (editedHosts?.[ id ]) {
			updateHostname(editedHosts[ id ])
				.unwrap()
				.catch((error: SerializedError) => {
					notify(NotificationType.ERROR, "Error updating the hostname.", error.message);
				});
		}

		toggleEditableRow(hostname)();
	};

	const handleDeleteHostname = (hostname: IHostname) => () => {
		deleteHostname(hostname.id)
			.unwrap()
			.catch((error: SerializedError) => {
				notify(NotificationType.ERROR, "Error deleting the hostname.", error.message);
			});
	};

	const toggleEditableRow = (hostname: IHostname) => () => {
		const { id } = hostname;

		if (editedHosts[ id ]) {
			setEditedHosts({
				...editedHosts,
				[ id ]: undefined
			});
		} else {
			setEditedHosts({
				...editedHosts,
				[ id ]: hostname
			});
		}
	};

	const renderHostnameSearch = () => {
		if (hostnameCount < 5) {
			return;
		}

		return (
			<Input
				placeholder="Search Hostnames..."
				suffix={ <Icon name="search" /> }
				onPressEnter={ handleSearch }
			/>
		);
	};

	const renderHostnameTable = () => {
		if (hostnameCount === 0) {
			return (
				<HostnameModal>
					<Button
						className="add-hostname-button"
						icon={ {
							name: "plus-circle"
						} }>
						Add Hostname
					</Button>
				</HostnameModal>
			);
		}

		return (
			<Table
				columns={ tableColumns }
				data={ hosts.map(getDataItem) }
			/>
		);
	};

	const renderAddHostnameButton = () => {
		if (hostnameCount === 0) {
			return;
		}

		return (
			<HostnameModal>
				<Button>Add Hostname</Button>
			</HostnameModal>
		);
	};

	return (
		<div className="hostname-table-container">
			<div className="table-header">
				{ renderAddHostnameButton() }
				{ renderHostnameSearch() }
			</div>
			{ renderHostnameTable() }
		</div>
	);
};

export default memo(HostnameTable);
