import { createRoot } from "react-dom/client";
import App from "Components/App";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import ErrorBoundary from "Components/ErrorBoundary";
import "./Styles/index.less";
import { persistor } from "Data/Redux/Persistor";
import { store } from "Data/Redux/Store";
import initFontAwesome from "src/FontAwesome";
import { BrowserRouter } from "react-router-dom";
import initGoogleAnalytics from "src/GoogleAnalytics";

initFontAwesome();
initGoogleAnalytics();

const appComponent = (
	<ErrorBoundary>
		<BrowserRouter>
			<Provider store={ store }>
				<PersistGate loading={ null } persistor={ persistor } >
					<App />
				</PersistGate>
			</Provider>
		</BrowserRouter>
	</ErrorBoundary>
);

createRoot(document.getElementById("app"))
	.render(appComponent);
