import { ReleaseChannels } from "Data/Objects/System";

export const releaseChannel = (() => {
	switch (__RELEASE_CHANNEL__) {
		case "production":
			return ReleaseChannels.PRODUCTION;
		case "staging":
			return ReleaseChannels.STAGING;
		default:
			return ReleaseChannels.DEVELOP;
	}
})();

export const isProduction = releaseChannel === ReleaseChannels.PRODUCTION;
export const isDevelop = releaseChannel === ReleaseChannels.DEVELOP;

export const isLocalhost = () => !window?.location?.hostname.includes("ddns.clintonelectronics.com");
